import { useEffect } from 'react';

import { Progress } from '@/noxx-ui/components/Progress';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';

const Home: NextPage = () => {
  const { error, isLoading } = useAuth0();
  const router = useRouter();

  useEffect(() => {
    console.log({ error, isLoading });
    if (isLoading) {
      return;
    }
    if (error) {
      router.replace(`/auth_error?error_description=${error.message}`);
      return;
    }
  }, [error, isLoading, router]);

  return (
    <div className="h-screen bg-[#1C1C1C] px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <Progress className="absolute h-full w-full z-10 bg-white" />
    </div>
  );
};

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <div>Redirecting you to the login...</div>,
});
