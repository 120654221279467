import classNames from 'classnames';

interface ProgressProps {
  className?: string;
}

export const Progress = ({ className }: ProgressProps) => (
  <div
    className={classNames(
      'flex items-center justify-center min-h-screen',
      className,
    )}
  >
    <div className="inline-block align-bottom overflow-hidden transform sm:my-2 sm:align-middle sm:p-6">
      <div className="flex justify-center self-center">
        <div className="animate-spin rounded-full h-24 w-24 border-t-2 border-b-2 border-indigo-500" />
      </div>
    </div>
  </div>
);
